import React from "react";
import HomePage from "./pages/HomePage";
import AttentionverseBlogPage from "./pages/AttentionverseBlogPage";
import ElseBlogPage from "./pages/ElseBlogPage";
import RepetitionBlogPage from './pages/RepetitionBlogPage'
import TheCoderBlogPage from './pages/TheCoderBlogPage'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';



function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path='/'
          element={<HomePage />}
        />
        <Route
          path='/attentionverse'
          element={<AttentionverseBlogPage />}
        />r
        <Route
          path='/else'
          element={<ElseBlogPage />}
        />
        <Route
          path='/repetition'
          element={<RepetitionBlogPage />}
        />
        <Route
          path='/thecoder'
          element={<TheCoderBlogPage />}
        />
        <Route path='*' exact={true} element={<HomePage />} />

      </Routes>
    </Router>
  );
}

export default App;



