import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

function Section({
  title,
  description,
  leftButtonText,
  rightButtonText,
  backgroundImage,
  shade,
  key,
  showDownArrow,
  leftButtonLink,
  rightButtonLink,
  message,
  tagLine
}) {


  const navigate = useNavigate();
  const {hash} = useLocation()

  useEffect(()=>{
      if(hash){
        const targetElement = document.getElementById(hash.substring(1))
          targetElement?.scrollIntoView({behavior: 'smooth'})
      }
  }, [hash])



  const navigateTo = () => {
    // Check if the link is external
    if (rightButtonLink && rightButtonLink.indexOf('http') > -1) {
      // Check if the link is an HTTPS link
      if (rightButtonLink.indexOf('https') > -1) {
        window.open(rightButtonLink, '_blank');
      } else {
        window.location.href = rightButtonLink;
      }
    } else {
      // Internal navigation with React Router
      navigate(`/#${rightButtonLink}`);
    }
  };


  return (
    <Wrap bgImage={backgroundImage} id={title} key={key}>
      <Shade style={{backgroundColor:`rgba(0,0,0, ${shade})`}}>
        <Fade bottom>
          <ItemText>
            <h1>{
              title === "attentionverse" ? (
                <span>
                <span style={{fontWeight:"900", color: 'white'}}>attention</span>
                <span style={{fontWeight:"300", color: "white"}}>verse</span>
                </span>
              ): (
                <span>
                <span style={{fontWeight:"900", color: 'white'}}>{title.split('.')[0]}</span>
                <span style={{fontWeight:"300", color: "white"}}>.{title.split('.')[1]}</span>
                </span>
              )
            }</h1>
            <p style={{fontSize:"18px"}}>{description}</p>
            <TagBox>{tagLine}</TagBox>
          </ItemText>
        </Fade>
        {message && <Message>
          <Box>
            {message}
          </Box>
        </Message>}
        <Buttons>
          <Fade top>
            <ButtonGroup>
              {leftButtonText && <LeftButton onClick={() => navigate(leftButtonLink)}>
                  {leftButtonText}
              </LeftButton>}
              {rightButtonText && <RightButton onClick={navigateTo}>
                  {rightButtonText}
              </RightButton>}
            </ButtonGroup>
          </Fade>
          {showDownArrow && <DownArrow src="/images/down-arrow.svg"></DownArrow>}
        </Buttons>
        </Shade>
    </Wrap>
  );
}

export default Section;

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: ${(props) => `url("/images/${props.bgImage}")`};
  scroll-snap-align: start;
`;

const Shade = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index:0;
`

const ItemText = styled.div`
  padding-top: 15vh;
  text-align: center;
  h1 {
    color: white;
  }
  p {
    color: white;
  }
`;

const Message = styled.div`
width: 80vw;
height: 20vh;
display: flex;
flex-direction: column;
justify-content:top;
@media (max-width: 768px) {
  align-items: center;
}
`;

const Box = styled.div`
width: 32vw;
display: flex;
flex-direction: column;
padding-top: 20px;
color: white;
font-size: 20px;
@media (max-width: 768px) {
  width: 70vw;
}
`;

const TagBox = styled.div`
display: flex;
flex-direction: column;
color: rgba(255, 255, 255, 0.7);
font-size: 16px;
font-weight: 300;
margin-top: -7.5px;
@media (max-width: 768px) {
  width: 70vw;
}
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom:30px;
`;

const ButtonGroup = styled.div`
  display: flex;
  // margin-bottom: 30px;
  gap: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftButton = styled.div`
  cursor: pointer;
  background-color: rgba(23, 26, 32, 0.5);
  height: 40px;
  width: 256px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 0.85;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

const RightButton = styled(LeftButton)`
  background-color: white;
  opacity: 0.65;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: black;
`;
const DownArrow = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 40px;
  animation: bounce infinite 1.5s;
`;
