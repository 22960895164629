import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectCars } from "../features/car/carSlice";
import { Link } from "react-router-dom";

function Header() {
  const [burgerStatus, setBurgerStatus] = useState(false);
  const cars = useSelector(selectCars);

  return (
    <Container>
      <Link to={{page:"/", hash:"attentionverse"}}>
        <img src="/images/logo.svg" alt="" />
      </Link>
      <Menu>
        {cars &&
          cars.map((car, index) => (
            <Link to={{page:"/", hash:car}} key={index}>
              {car}
            </Link>
          ))}
      </Menu>
      <RightMenu>
        {/* <a href="/">Shop</a>
        <a href="/">Account</a> */}
        <CustomMenu
          style={{color: "white", backgroundColor: 'white', borderRadius: '1px'}}
          onClick={() => {
            setBurgerStatus(true);
          }}
        />
      </RightMenu>
      <BurgerNav show={burgerStatus}>
        <MenuTop>
          <Link to={{page:"/", hash:"attentionverse"}} onClick={() => {setBurgerStatus(false);}}>
            <img src="/images/logo-black.svg" alt="" />
          </Link>
          <CustomClose
            onClick={() => {
              setBurgerStatus(false);
            }}
          />
        </MenuTop>
        {cars &&
          cars.map((car, index) => (
            <li key={index}>
              <Link to={{page:"/", hash:car}} key={index} onClick={() => {setBurgerStatus(false);}}>
              {car}
            </Link>
            </li>
          ))}
         <li style={{marginTop:'auto'}}>
          <center>
            <a href="privacy-policy.html" style={{fontSize:'0.9em', color:'grey', fontWeight: 100}}>Privacy Policy</a>
            <span style={{color:'grey', fontSize: '0.8em'}}>  |  </span>
            <a href="terms.html" style={{fontSize:'0.9em', color:'grey', fontWeight: 100}}>Terms of Service</a></center>
        </li>
        <li>
          <center><span style={{fontSize:'0.75em', color:'grey'}}>&copy; 2023 Attentionverse. All rights reserved.</span></center>
        </li>
        {/*<li>
          <a href="/">Used Inventory</a>
        </li>
        <li>
          <a href="/">Cybertruck</a>
        </li>
        <li>
          <a href="/">Roadster</a>
        </li> */}
      </BurgerNav>
    </Container>
  );
}


const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;
`;

const MenuTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom:5em;
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  a {
    font-weight: 600;
    padding: 0 10px;
    flex-wrap: nowrap;
    color: white;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  a {
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
    color: white;
  }
`;

const CustomMenu = styled(MenuIcon)`
  cursor: pointer;
`;

const BurgerNav = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  background:white;
  list-style: none;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  li {
    padding: 10px 0;
      color: black;
      a{
        font-weight: 600;
        color: black;
      }
      
  }
  z-index: 10;
`;

const CustomClose = styled(CloseIcon)`
  align-self: end;
  cursor: pointer;
  margin-right: 10px;
`;

export default Header;
