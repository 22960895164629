import React from 'react'
import Header from '../components/Header'
import Home from '../components/Home'

export default function HomePage() {
    return (
        <div className="App">
            <Header />
            <Home />
        </div>
    )
}
