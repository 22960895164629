import React from "react";
import styled from "styled-components";
import Section from "./Section";

function Home() {

  const messageList = [
  "Live Interfaces are the surface of collaboration & value creation of the future", 
  "Live interfaces are the site of dance of human and machine intelligence", 
  ];

  const randomMessage= messageList[Math.floor(Math.random() * messageList.length)];

  return (
    <Container>
      <Section
        title="attentionverse"
        key={1}
        description="Universe of Live Interfaces"
        backgroundImage="attentionverselive.jpg"
        shade={0}
        leftButtonText="Thesis"
        rightButtonText="Explore"
        leftButtonLink={"/attentionverse"}
        rightButtonLink={"thecoder.live"}
        message={randomMessage}
      />
      <Section
        title="thecoder.live"
        key={2}
        description="Your AI Technology Team"
        backgroundImage="thecoderlive.jpg"
        shade={0}
        leftButtonText="Thesis"
        rightButtonText="App Maker"
        leftButtonLink={"/thecoder"}
        rightButtonLink={"https://www.thecoder.live"}
        tagLine={"a live interface for software"}
      />
      {/* <Section
        title="thestartup.live"
        description="Order Online for Touchless Delivery"
        backgroundImage="model-y.jpg"
        leftButtonText="Learn More"
        rightButtonText="Join Us"
      />
      <Section
        title="theorder.live"
        description="Order Online for Touchless Delivery"
        backgroundImage="model-3.jpg"
        leftButtonText="Learn More"
        rightButtonText="Join Us"
      />
      <Section
        title="theparty.live"
        description="Order Online for Touchless Delivery"
        backgroundImage="model-x.jpg"
        leftButtonText="Learn More"
        rightButtonText="Join Us"
      /> */}
      <Section
        title="else.live"
        key={3}
        description="α β γ δ ε η θ ∑ ∆ Ω σ φ ω λ π μ"
        backgroundImage="elselive.jpg"
        shade={0}
        leftButtonText="Thesis"
        // rightButtonText="Prototype"
        leftButtonLink={"/else"}
        // rightButtonLink= "https://else.live"
        tagLine="a live interface for discovery"
      />
       <Section
        title="terafac.live"
        key={4}
        description="Simulating Work Environments"
        backgroundImage="terafaclive.png"
        shade={0.6}
        // leftButtonText="Thesis"
        rightButtonText="Simulate Jobs"
        //leftButtonLink={"/else"}
        rightButtonLink= "https://terafac.live"
        tagLine="a live interface for work"
      />
      <Section
        title="repetition.live"
        key={5}
        description="Catalysing Capacity Creation"
        backgroundImage="repetitionlive.jpg"
        shade={0.2}
        leftButtonText="Thesis"
        // rightButtonText="Android App"
        leftButtonLink={"/repetition"}
        // rightButtonLink={"https://play.google.com/store/apps/details?id=live.repetition.app"}
        tagLine="a live interface for skilling"
      />
      
    </Container>
  );
}

export default Home;


const Container = styled.div`
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll; 
  overflow-x: hidden;
  overscroll-behavior: none;
`;
