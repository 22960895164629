import React from 'react'
import Blog from '../components/Blog'
import data from '../config/blog.json'

export default function RepetitionBlogPage() {
    return (
        <div>
            <Blog
                data={data.repetitionlive}
            />
        </div>
    )
}
